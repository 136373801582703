import {
  Card,
  Page,
  Box,
  Layout,
  PageSection,
  Cell,
  CopyClipboard,
  Button,
  FormField,
  Input,
  TextButton,
  CircularProgressBar,
  MarketingLayout,
  Badge,
  WixDesignSystemProvider,
  Loader
} from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import { GetStarted, DuplicateSmall, Add, Reviews } from '@wix/wix-ui-icons-common'
import React from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { ReactTagManager } from 'react-gtm-ts';

const plans = {
  "starter": {
    triggersLimit: 50,
    actionsLimit: 50,
  },
  "pro": {
    triggersLimit: 500,
    actionsLimit: 500,
  },
  "team": {
    triggersLimit: 5000,
    actionsLimit: 5000,
  },
  "enterprise": {
    triggersLimit: -1,
    actionsLimit: -1,
  }
}

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

const appId = "472c3ee2-8c4d-47a1-8b49-d7a4f0f45a57"

function App() {
  ReactTagManager.init(tagManagerArgs)

  const [dashboard, setDashboard] = React.useState(null);
  const [isErrored, setIsErrored] = React.useState(false);

  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')

  React.useEffect(() => {
    axios.get('https://certifiedcode.editorx.io/connector/_functions/dashboard', {
      params: {
        instance: instance
      }
    }).then((response) => {
      if (response?.data?.success === false || !response?.data?.instance) {
        setIsErrored(true);
      } else { setDashboard(response.data); setIntercomIdentity(); }
    })
      .catch((error) => {
        setIsErrored(true);
      });
  }, []);
  
  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=472c3ee2-8c4d-47a1-8b49-d7a4f0f45a57&redirectUrl=https://certifiedcode.editorx.io/connector/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Header
            title="Connector"
            subtitle="Manage your Connector connections with Zapier"
          />
          <Page.Content>
            <Loader size="large" />
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    )
  }

  const BASE_URL = `https://certifiedcode.editorx.io/connector/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
  }

  // if (!dashboard) {
  //   return (
  //     <WixDesignSystemProvider features={{ newColorsBranding: true }}>
  //       <Page>
  //         <Page.Content>
  //           <Box padding="10px" align="center" verticalAlign="middle" height={"90vh"} direction="vertical">
  //             <Loader text="Loading..." />
  //           </Box>
  //         </Page.Content>
  //       </Page>
  //     </WixDesignSystemProvider>
  //   )
  // }

  if (!instance) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page>
          <Page.Content>
            <Box padding="10px" align="center" verticalAlign="middle" height={"90vh"} direction="vertical">
              <Loader status="error" text="Please open this app via Wix dashboard." />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    )
  }

  const currentPlanName = dashboard ? (dashboard['instance']['availablePlans'] ? dashboard['instance']['availablePlans'][0]['packageName'] : 'starter') : null
  const currentPlanLimit = currentPlanName ? plans[currentPlanName] : null

  // if url include ?widget=activity then show activity widget
  if (new URLSearchParams(window.location.search).get('widget') === 'activity') {
    return (<WixDesignSystemProvider features={{ newColorsBranding: true }}>
      Test
    </WixDesignSystemProvider>)
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page>
        <Page.Header
          title="Connector"
          subtitle="Manage your Connector connections with Zapier"
          actionsBar={
            <Button skin="inverted" prefixIcon={<GetStarted />} onClick={() => { window.Intercom("showArticle", 6849770) }}>
              Setup Guide
            </Button>
          }
        />
        <Page.Content>
          <Layout>
            <Cell span={8}>
              <Card>
                <Card.Header title="Credentials" />
                <Card.Divider />
                <Card.Content>
                  <Box>
                    <CopyClipboard value={dashboard ? dashboard['apiKey'] : ""} resetTimeout={1500}>
                      {({ isCopied, copyToClipboard }) => (
                        <FormField label="Your API Key">
                          <Input
                            type="password"
                            readOnly
                            value={dashboard ? dashboard['apiKey'] : ""}
                            suffix={
                              <Box verticalAlign="middle" marginRight="SP1">
                                <TextButton
                                  onClick={() => copyToClipboard()}
                                  size="small"
                                  prefixIcon={<DuplicateSmall />}
                                >
                                  {!isCopied ? 'Copy' : 'Copied!'}
                                </TextButton>
                              </Box>
                            }
                            status={!isErrored ? "loading" : (!dashboard ? "error" : undefined)}
                          />
                        </FormField>
                      )}
                    </CopyClipboard>
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={4}>
              <Card>
                <Card.Header
                  title="Usage Data"
                  suffix={
                    <Button
                      onClick={() => { if (dashboard) window.open(`https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${dashboard['instance']['instance']['instanceId']}`, '_blank') }}
                      size="small"
                      disabled={!dashboard}
                      priority='secondary'
                      prefixIcon={<Add />}
                    >
                      Increase Quota
                    </Button>
                  }
                />
                <Card.Divider />
                <Card.Content>
                  <Box gap="18px">
                    <Cell span={4}>
                      <CircularProgressBar showProgressIndication skin={"premium"} value={(dashboard && currentPlanLimit && !isErrored) ? `${dashboard['currentUsage']['actions']}/${currentPlanLimit['actionsLimit']}` : (isErrored ? "error" : `loading...`)} label={(dashboard && currentPlanLimit && !isErrored) ? `${dashboard['currentUsage']['actions']} out of ${currentPlanLimit['actionsLimit']} Actions used` : (isErrored ? "error" : `loading...`)} />
                    </Cell>
                    <Cell span={4}>
                      <CircularProgressBar showProgressIndication skin={"premium"} value={(dashboard && currentPlanLimit && !isErrored) ? `${dashboard['currentUsage']['triggers']}/${currentPlanLimit['triggersLimit']}` : (isErrored ? "error" : `loading...`)} label={(dashboard && currentPlanLimit && !isErrored) ? `${dashboard['currentUsage']['triggers']} out of ${currentPlanLimit['triggersLimit']} Triggers used` : (isErrored ? "error" : `loading...`)} />
                    </Cell>
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
          </Layout>
          <Layout>
            <Cell>
              <PageSection title="Suggested for you" showDivider />
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Learn more about ID"
                  description="Get to know about IDs before setting up Zaps helps you to get the most out of your Zaps."
                  actions={<Button size="small" onClick={() => { window.Intercom("showArticle", 6887369) }}>Read Article</Button>}
                  size="small"
                  badge={<Badge size="small">Essential</Badge>}
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Connector Supported APIs"
                  description="List of supported Wix business solutions apps with Connector."
                  actions={<Button size="small">Read Documentation</Button>}
                  size="small"
                  hiddenBadge
                />
              </Card>
            </Cell>
            {/* <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Love Connector?"
                  description="Share your experience with Wix and help others to get the most out of Connector."
                  actions={<Button as="a" href={`https://www.wix.com/app-market/add-review/${appId}`} target="_blank" size="small" prefixIcon={<Reviews />} skin="dark" >Write an review on Wix</Button>}
                  size="small"
                  hiddenBadge
                />
              </Card>
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
